<template>
  <div
    class="analytics__item"
    :class="{ active: current_type === type }"
    @click="click"
  >
    <div class="analytics__item-title">{{ title }}</div>
    <div class="analytics__item-sum">
      {{ value }}
    </div>
  </div>
</template>
<script>
  import { formatMoney } from '@/utils/formatMoney'

  export default {
    props: {
      value: [String, Number],
      title: String,
      type: String,
      current_type: String
    },
    methods: {
      click() {
        this.$emit('click', this.type)
      },
      formatPrice(val) {
        return formatMoney(val)
      }
    }
  }
</script>

<style scoped lang="scss">
  .analytics__item {
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    width: 180px;
    color: #313131;
    cursor: pointer;
    &.active {
      border-color: #00a3ff;
      background: #ddf3ff;
    }
    &-title {
      font-size: 14px;
      line-height: 16px;
      color: #888888;
      margin-bottom: 8px;
    }
  }
</style>
