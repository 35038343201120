var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-height widget-row mt-3"
  }, [_c('div', {
    staticClass: "widget-total"
  }, [_c('b-card', {
    staticClass: "p-0 mb-0"
  }, [_c('div', {
    staticClass: "widget-title"
  }, [_vm._v("Всего за период")]), _c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "analytics__item"
  }, [_c('div', {
    staticClass: "analytics__item-title"
  }, [_vm._v("Оборот в роз. ценах, ₽")]), _c('div', {
    staticClass: "analytics__item-sum"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.retailSum)) + " ")])])]), _c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "analytics__item"
  }, [_c('div', {
    staticClass: "analytics__item-title"
  }, [_vm._v("Оборот в закуп. ценах, ₽")]), _c('div', {
    staticClass: "analytics__item-sum"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.purchaseSum)) + " ")])])])])], 1), _c('div', {
    staticClass: "widget-graph chart-block pl-0 ml-3"
  }, [_c('b-card', {
    staticClass: "p-0"
  }, [_c('div', {
    staticClass: "widget-title"
  }, [_vm._v("Детализация за период")]), _c('line-chart-generator', {
    ref: "salesAnalysis",
    attrs: {
      "chart-options": _vm.chartOptions,
      "chart-data": _vm.chartData,
      "chart-id": _vm.chart_id,
      "plugins": _vm.plugins,
      "height": _vm.chart_settings.height
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }