var render = function render(){
  var _vm$totalInfo$markup, _vm$totalInfo$profita;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "widget-height widget-row mt-3"
  }, [_c('div', {
    staticClass: "widget-total"
  }, [_c('b-card', {
    staticClass: "p-0 mb-0"
  }, [_c('div', {
    staticClass: "widget-title"
  }, [_vm._v("Всего за период")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "mr-3 mt-3"
  }, [_c('info-widget', {
    attrs: {
      "title": "Прибыль, ₽",
      "value": _vm.formatPrice(_vm.totalInfo.profit),
      "type": 'margin',
      "current_type": _vm.type
    },
    on: {
      "click": function (val) {
        return _vm.type = val;
      }
    }
  }), _c('info-widget', {
    staticClass: "mt-3",
    attrs: {
      "title": "Ср. наценка (факт), %",
      "type": 'markup',
      "current_type": _vm.type,
      "value": ((_vm$totalInfo$markup = _vm.totalInfo.markup) === null || _vm$totalInfo$markup === void 0 ? void 0 : _vm$totalInfo$markup.toFixed(2)) || 0 + ' %'
    },
    on: {
      "click": function (val) {
        return _vm.type = val;
      }
    }
  })], 1), _c('div', {
    staticClass: "mt-3"
  }, [_c('info-widget', {
    attrs: {
      "title": "Выручка, ₽",
      "value": _vm.formatPrice(_vm.totalInfo.retail),
      "type": 'retail',
      "current_type": _vm.type
    },
    on: {
      "click": function (val) {
        return _vm.type = val;
      }
    }
  }), _c('info-widget', {
    staticClass: "mt-3",
    attrs: {
      "title": "Рентабельность, %",
      "value": ((_vm$totalInfo$profita = _vm.totalInfo.profitability) === null || _vm$totalInfo$profita === void 0 ? void 0 : _vm$totalInfo$profita.toFixed(2)) || 0 + ' %',
      "type": 'profit',
      "current_type": _vm.type
    },
    on: {
      "click": function (val) {
        return _vm.type = val;
      }
    }
  })], 1)])])], 1), _c('div', {
    staticClass: "widget-graph chart-block pl-0 ml-3"
  }, [_c('b-card', {
    staticClass: "p-0"
  }, [_c('div', {
    staticClass: "widget-title"
  }, [_vm._v("Детализация за период")]), _c('bar', {
    ref: "marginByDate",
    attrs: {
      "chart-options": _vm.chartOptions,
      "chart-data": _vm.chart_data,
      "chart-id": "column",
      "height": _vm.chart_settings.height,
      "css-classes": "chart_sum"
    }
  })], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }