var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        return _vm.sheet = false;
      },
      expression: "() => (sheet = false)"
    }],
    staticClass: "box",
    on: {
      "click": function ($event) {
        _vm.sheet = !_vm.sheet;
      }
    }
  }, [_c('div', [_vm._v("Группировка")]), _vm.counter ? _c('div', {
    staticClass: "counter"
  }, [_vm._v(" " + _vm._s(_vm.counter) + " ")]) : _vm._e(), _c('arrow-svg', {
    staticClass: "arrow-svg",
    class: {
      rotate: _vm.sheet
    }
  }), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.sheet ? _c('div', {
    staticClass: "sheet",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "item-none",
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(" Без группировки ")]), _c('draggable', {
    attrs: {
      "group": "items"
    },
    on: {
      "start": function ($event) {
        _vm.drag = true;
      },
      "end": function ($event) {
        _vm.drag = false;
      }
    },
    model: {
      value: _vm.items,
      callback: function ($$v) {
        _vm.items = $$v;
      },
      expression: "items"
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "item"
    }, [_c('e-checkbox', {
      attrs: {
        "checked": item.grouped,
        "value": item.id
      },
      on: {
        "click": function ($event) {
          return _vm.selectGroup(item);
        }
      }
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('img', {
      staticClass: "ml-auto move_button",
      attrs: {
        "data-v-c31cc8c2": "",
        "src": "/img/icons/move_button.svg",
        "alt": ""
      }
    })], 1);
  }), 0)], 1) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }