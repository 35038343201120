var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "analytics__item",
    class: {
      active: _vm.current_type === _vm.type
    },
    on: {
      "click": _vm.click
    }
  }, [_c('div', {
    staticClass: "analytics__item-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "analytics__item-sum"
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }