<template>
  <div
    :style="{
      'margin-left': 25 * (path.length ? path.length - 1 : 0) + 'px'
    }"
  >
    <div
      v-if="row.product?.id"
      class="row-product"
    >
      <e-checkbox
        class="mr-3"
        :checked="selected"
        :value="row.product?.id"
        @click="selectProduct(row.product?.id)"
      />
      <div class="d-flex flex-column">
        <div class="d-flex">{{ row.product.name }}</div>
        <div class="category-path">
          <span class="articul">{{ row.product.articul }}</span>
          <span>{{ row.product.supplier?.name }}</span>
        </div>
      </div>
    </div>
    <div
      v-else-if="row.category?.id || row.supplier?.id || row.storage?.id || row.branch?.id"
      class="row-category"
      @click="openItem"
    >
      <div class="arrow">
        <div v-if="!loading_category[getPathIds()]">
          <img
            v-if="isOpened(row.category?.id)"
            class="img-row"
            src="/img/icons/oppened-row.svg"
            alt=""
          />
          <img
            v-else
            class="img-row"
            src="/img/icons/closed-row.svg"
            alt=""
          />
        </div>
        <b-spinner
          v-else
          variant="primary"
          class="arrow-spinner"
          small
        />
      </div>

      <div style="overflow: hidden; flex: 1">
        <div class="d-flex align-items-center">
          <div
            v-b-tooltip.hover
            class="mr-2"
            style="min-width: 20px"
            :title="type_mapping[path[path.length - 1]?.group]"
          >
            <img
              :src="'/img/icons/analytics/' + path[path.length - 1]?.group + '.svg'"
              alt=""
            />
          </div>
          <div class="d-flex overflow-hidden">
            {{ getName() }}
          </div>
        </div>

        <div
          v-if="path[path.length - 1]?.group === 'category'"
          class="category-path"
        >
          {{ setCategoryPath() }}
        </div>
        <div
          v-if="path[path.length - 1]?.group === 'supplier'"
          class="category-path"
        >
          ИНН: {{ row.supplier?.inn || '-' }} КПП: {{ row.supplier?.kpp || '-' }}
        </div>
        <div
          v-if="path[path.length - 1]?.group === 'branch'"
          class="category-path"
        >
          {{
            row?.branch?.address?.city + ', ' + row?.branch?.address?.street + ', ' + row?.branch?.address?.house || '-'
          }}
        </div>
      </div>
    </div>
    <div v-else>
      <div style="font-weight: 600">Итого</div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'AnalyticsItemRow',
    props: {
      row: Object,
      loading_category: Object,
      type: String,
      selected: Boolean,
      items: {
        type: Array,
        default: () => ['category', 'branch', 'operation', 'storage', 'supplier', 'entity', 'product']
      }
    },
    data() {
      return {
        type_mapping: {
          category: 'Группа товаров',
          operation: 'Операция',
          storage: 'Место хранения',
          supplier: 'Поставщик',
          entity: 'Организация',
          branch: 'Филиал'
        },
        product_selected: []
      }
    },
    computed: {
      ...mapGetters({
        checked: 'analytics/getChecked',
        abcChecked: 'analytics/getAbcChecked'
      }),
      path() {
        const path = []
        for (const item of this.items) {
          if (this.row[item]) path.push({ group: item, item: this.row[item] })
        }
        return path
      }
    },
    methods: {
      ...mapActions({
        setChecked: 'analytics/setChecked',
        setAbcChecked: 'analytics/setAbcChecked'
      }),
      selectProduct(val) {
        this.$emit('select_product', val)
      },
      getName() {
        return this.path[this.path.length - 1]?.item?.name
      },
      getChecked() {
        return this.type === 'abc' ? this.abcChecked : this.checked
      },
      isOpened() {
        const structure = this.path.map((el) => el.group)
        let flag = false
        const tree_walker = (tree, index) => {
          const current_path = structure[index]
          let find_index = tree.findIndex((el) => el?.checked === this.row[current_path]?.id)
          if (find_index !== -1) {
            tree_walker(tree[find_index].children, index + 1)
          }

          if (structure.length - 1 === index && find_index !== -1) {
            flag = true
          }
        }
        tree_walker(this.getChecked(), 0)

        return flag
      },
      getPathIds() {
        return this.path.map((el) => el.item.id).join()
      },

      close() {
        const structure = this.path.map((el) => el.group)

        const tree_walker = (tree, index) => {
          const current_path = structure[index]
          let find_index = tree.findIndex((el) => el.checked === this.row[current_path]?.id)
          if (find_index !== -1) {
            tree_walker(tree[find_index].children, index + 1)
          }

          if (structure.length - 1 === index && find_index !== -1) {
            tree.splice(find_index, 1)
          }
        }
        tree_walker(this.getChecked(), 0)

        this.$emit('open_category', this.getPathIds())
      },
      openItem() {
        if (this.isOpened()) return this.close()
        const structure = this.path.map((el) => el.group)

        const tree_walker = (tree, index) => {
          const current_path = structure[index]
          let find = tree.find((el) => el?.checked === this.row[current_path]?.id)
          if (!find) {
            find = { children: [], group: current_path, checked: this.row[current_path]?.id }
            tree.push(find)
          }

          if (structure.length - 1 > index) {
            tree_walker(find.children, index + 1)
          }
        }
        tree_walker(this.getChecked(), 0)

        this.$emit('open_category', this.getPathIds())
      },
      setCategoryPath() {
        const category = this.row?.category
        let path = category?.parent_groups?.name || ''
        let parent = category?.parent_groups
        while (parent?.parent_groups?.name) {
          path = path + ' / ' + parent.parent_groups.name
          parent = parent.parent_groups
        }
        return path || '-'
      }
    }
  }
</script>

<style scoped lang="scss">
  .category-path {
    color: #888;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 6px;
    display: flex;
    .articul {
      margin-right: 10px;
    }
  }
  .row-product {
    display: flex;
    align-items: center;
  }
  .row-category {
    cursor: pointer;
    display: flex;
    font-weight: 500;
    .img-row {
      margin-right: 10px;
    }
    .arrow-spinner {
      margin-right: 10px;
      margin-top: 0px;
      width: 12px;
      height: 12px;
    }
    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
    }
  }
</style>
