<template>
  <div>
    <e-input />
  </div>
</template>
<script>
  export default {}
</script>

<style scoped lang="scss"></style>
