import { formatMoney } from '@/utils/formatMoney'

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.background = '#313131'
    tooltipEl.style.borderRadius = '6px'
    tooltipEl.style.color = 'white'
    tooltipEl.style.opacity = 1
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, 0)'
    tooltipEl.style.transition = 'all .1s ease'

    const table = document.createElement('div')
    table.style.margin = '0px'

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

export const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || []
    // const bodyLines = tooltip.body.map((b) => b.lines)
    const tableHead = document.createElement('div')
    tableHead.style.flex = '1'
    tableHead.style.display = 'flex'
    titleLines.forEach((title) => {
      const tr = document.createElement('div')
      tr.style.borderWidth = 0
      tr.style.display = 'flex'
      tr.style.flex = '1'
      const th = document.createElement('div')
      th.style.borderWidth = 0
      const text = document.createTextNode(title)

      th.appendChild(text)
      tr.appendChild(th)
      tableHead.appendChild(tr)
    })

    const tableBody = document.createElement('div')

    const add_row = ({ title, text, mt }) => {
      const row = document.createElement('div')
      row.style.display = 'flex'
      row.style.flex = '1'
      if (mt) row.style.marginTop = '4px'
      row.style.justifyContent = 'space-between'
      row.style.alignItems = 'space-between'
      const desc = document.createElement('div')
      desc.innerHTML = title
      const textHtml = document.createElement('div')
      textHtml.innerHTML = text
      row.appendChild(desc)
      row.appendChild(textHtml)
      return row
    }

    // td.appendChild(span)
    const raw = context.tooltip.dataPoints[0].raw
    tableBody.appendChild(add_row({ title: 'Прибыль, ₽', text: formatMoney(raw?.profit || 0, 2) }))
    tableBody.appendChild(add_row({ title: 'Выручка, ₽', text: formatMoney(raw?.retail || 0, 2) }))
    tableBody.appendChild(add_row({ title: 'Возвраты, ₽', text: formatMoney(raw?.return || 0, 2) }))
    tableBody.appendChild(add_row({ title: 'Наценка, %', text: raw?.markup?.toFixed(2) || 0 }))
    tableBody.appendChild(add_row({ title: 'Рентабельность, %', text: (raw?.profitability * 100)?.toFixed(2) || 0 }))

    // tableRoot.style.background = 'white'
    const tableRoot = tooltipEl.querySelector('div')
    tableRoot.style.backgrodund = 'white'
    tableRoot.style.display = 'flex'
    tableRoot.style.flex = '1'
    tableRoot.style.flexDirection = 'column'
    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove()
    }

    // Add new children
    tableRoot.appendChild(tableHead)
    tableRoot.appendChild(tableBody)
  }

  const { offsetLeft: positionX } = chart.canvas

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1
  tooltipEl.style.width = '200px'
  tooltipEl.style.display = 'flex'
  tooltipEl.style.top = tooltip.caretY + 10 + 'px'
  tooltipEl.style.font = tooltip.options.bodyFont.string
  tooltipEl.style.padding = 12 + 'px'
  var currentTooltip = tooltip.dataPoints[0].dataIndex
  var keys = Object.keys(tooltip.dataPoints[0].dataset.data)

  if (currentTooltip < keys.length / 2) {
    tooltipEl.style.left = positionX + 115 + tooltip.caretX + 'px'
    tooltipEl.classList.add('tooltipel-arrow-left')
    tooltipEl.classList.remove('tooltipel-arrow-right')
  } else {
    tooltipEl.style.left = positionX - 115 + tooltip.caretX + 'px'
    tooltipEl.classList.add('tooltipel-arrow-right')
    tooltipEl.classList.remove('tooltipel-arrow-left')
  }
}
