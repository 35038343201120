var render = function render(){
  var _vm$row$product, _vm$row$product2, _vm$row$product$suppl, _vm$row$category, _vm$row$supplier, _vm$row$storage, _vm$row$branch, _vm$row$category2, _vm$path, _vm$path2, _vm$path3, _vm$path4, _vm$row$supplier2, _vm$row$supplier3, _vm$path5, _vm$row, _vm$row$branch2, _vm$row$branch2$addre, _vm$row2, _vm$row2$branch, _vm$row2$branch$addre, _vm$row3, _vm$row3$branch, _vm$row3$branch$addre;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: {
      'margin-left': 25 * (_vm.path.length ? _vm.path.length - 1 : 0) + 'px'
    }
  }, [(_vm$row$product = _vm.row.product) !== null && _vm$row$product !== void 0 && _vm$row$product.id ? _c('div', {
    staticClass: "row-product"
  }, [_c('e-checkbox', {
    staticClass: "mr-3",
    attrs: {
      "checked": _vm.selected,
      "value": (_vm$row$product2 = _vm.row.product) === null || _vm$row$product2 === void 0 ? void 0 : _vm$row$product2.id
    },
    on: {
      "click": function ($event) {
        var _vm$row$product3;

        return _vm.selectProduct((_vm$row$product3 = _vm.row.product) === null || _vm$row$product3 === void 0 ? void 0 : _vm$row$product3.id);
      }
    }
  }), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm._v(_vm._s(_vm.row.product.name))]), _c('div', {
    staticClass: "category-path"
  }, [_c('span', {
    staticClass: "articul"
  }, [_vm._v(_vm._s(_vm.row.product.articul))]), _c('span', [_vm._v(_vm._s((_vm$row$product$suppl = _vm.row.product.supplier) === null || _vm$row$product$suppl === void 0 ? void 0 : _vm$row$product$suppl.name))])])])], 1) : (_vm$row$category = _vm.row.category) !== null && _vm$row$category !== void 0 && _vm$row$category.id || (_vm$row$supplier = _vm.row.supplier) !== null && _vm$row$supplier !== void 0 && _vm$row$supplier.id || (_vm$row$storage = _vm.row.storage) !== null && _vm$row$storage !== void 0 && _vm$row$storage.id || (_vm$row$branch = _vm.row.branch) !== null && _vm$row$branch !== void 0 && _vm$row$branch.id ? _c('div', {
    staticClass: "row-category",
    on: {
      "click": _vm.openItem
    }
  }, [_c('div', {
    staticClass: "arrow"
  }, [!_vm.loading_category[_vm.getPathIds()] ? _c('div', [_vm.isOpened((_vm$row$category2 = _vm.row.category) === null || _vm$row$category2 === void 0 ? void 0 : _vm$row$category2.id) ? _c('img', {
    staticClass: "img-row",
    attrs: {
      "src": "/img/icons/oppened-row.svg",
      "alt": ""
    }
  }) : _c('img', {
    staticClass: "img-row",
    attrs: {
      "src": "/img/icons/closed-row.svg",
      "alt": ""
    }
  })]) : _c('b-spinner', {
    staticClass: "arrow-spinner",
    attrs: {
      "variant": "primary",
      "small": ""
    }
  })], 1), _c('div', {
    staticStyle: {
      "overflow": "hidden",
      "flex": "1"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-2",
    staticStyle: {
      "min-width": "20px"
    },
    attrs: {
      "title": _vm.type_mapping[(_vm$path = _vm.path[_vm.path.length - 1]) === null || _vm$path === void 0 ? void 0 : _vm$path.group]
    }
  }, [_c('img', {
    attrs: {
      "src": '/img/icons/analytics/' + ((_vm$path2 = _vm.path[_vm.path.length - 1]) === null || _vm$path2 === void 0 ? void 0 : _vm$path2.group) + '.svg',
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "d-flex overflow-hidden"
  }, [_vm._v(" " + _vm._s(_vm.getName()) + " ")])]), ((_vm$path3 = _vm.path[_vm.path.length - 1]) === null || _vm$path3 === void 0 ? void 0 : _vm$path3.group) === 'category' ? _c('div', {
    staticClass: "category-path"
  }, [_vm._v(" " + _vm._s(_vm.setCategoryPath()) + " ")]) : _vm._e(), ((_vm$path4 = _vm.path[_vm.path.length - 1]) === null || _vm$path4 === void 0 ? void 0 : _vm$path4.group) === 'supplier' ? _c('div', {
    staticClass: "category-path"
  }, [_vm._v(" ИНН: " + _vm._s(((_vm$row$supplier2 = _vm.row.supplier) === null || _vm$row$supplier2 === void 0 ? void 0 : _vm$row$supplier2.inn) || '-') + " КПП: " + _vm._s(((_vm$row$supplier3 = _vm.row.supplier) === null || _vm$row$supplier3 === void 0 ? void 0 : _vm$row$supplier3.kpp) || '-') + " ")]) : _vm._e(), ((_vm$path5 = _vm.path[_vm.path.length - 1]) === null || _vm$path5 === void 0 ? void 0 : _vm$path5.group) === 'branch' ? _c('div', {
    staticClass: "category-path"
  }, [_vm._v(" " + _vm._s(((_vm$row = _vm.row) === null || _vm$row === void 0 ? void 0 : (_vm$row$branch2 = _vm$row.branch) === null || _vm$row$branch2 === void 0 ? void 0 : (_vm$row$branch2$addre = _vm$row$branch2.address) === null || _vm$row$branch2$addre === void 0 ? void 0 : _vm$row$branch2$addre.city) + ', ' + ((_vm$row2 = _vm.row) === null || _vm$row2 === void 0 ? void 0 : (_vm$row2$branch = _vm$row2.branch) === null || _vm$row2$branch === void 0 ? void 0 : (_vm$row2$branch$addre = _vm$row2$branch.address) === null || _vm$row2$branch$addre === void 0 ? void 0 : _vm$row2$branch$addre.street) + ', ' + ((_vm$row3 = _vm.row) === null || _vm$row3 === void 0 ? void 0 : (_vm$row3$branch = _vm$row3.branch) === null || _vm$row3$branch === void 0 ? void 0 : (_vm$row3$branch$addre = _vm$row3$branch.address) === null || _vm$row3$branch$addre === void 0 ? void 0 : _vm$row3$branch$addre.house) || '-') + " ")]) : _vm._e()])]) : _c('div', [_c('div', {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("Итого")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }