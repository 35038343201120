<template>
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.517962 1.09C0.234973 1.36305 0.235053 1.81641 0.518139 2.08936L5.62935 7.01762C5.8362 7.21707 6.1638 7.21707 6.37065 7.01762L11.4819 2.08936C11.7649 1.81641 11.765 1.36305 11.482 1.09C11.2131 0.83052 10.7871 0.83046 10.5181 1.08986L6 5.44682L1.48193 1.08986C1.21294 0.830461 0.786881 0.830521 0.517962 1.09Z"
      fill="#313131"
    />
  </svg>
</template>
<script>
  export default {
    name: 'ArrowSvg',
    methods: {
      click(e) {
        this.$emit('click', e)
      }
    }
  }
</script>
