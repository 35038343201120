<template>
  <div class="widget-height widget-row mt-3">
    <div class="widget-total">
      <b-card class="p-0 mb-0">
        <div class="widget-title">Всего за период</div>
        <div class="mt-3">
          <div class="analytics__item">
            <div class="analytics__item-title">Оборот в роз. ценах, ₽</div>
            <div class="analytics__item-sum">
              {{ formatPrice(retailSum) }}
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="analytics__item">
            <div class="analytics__item-title">Оборот в закуп. ценах, ₽</div>
            <div class="analytics__item-sum">
              {{ formatPrice(purchaseSum) }}
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div class="widget-graph chart-block pl-0 ml-3">
      <b-card class="p-0">
        <div class="widget-title">Детализация за период</div>
        <line-chart-generator
          ref="salesAnalysis"
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chart_id"
          :plugins="plugins"
          :height="chart_settings.height"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  } from 'chart.js'
  import { mapGetters } from 'vuex'
  import { formatMoney } from '@/utils/formatMoney'

  ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement)

  export default {
    name: 'SalesAnalysisPeriod',
    components: {
      LineChartGenerator
    },
    props: {
      period: Array,
      default: () => [new Date(), new Date()]
    },
    apollo: {
      SalesAnalyticsGraph: {
        query: require('../gql/SalesAnalyticsGraph.gql'),
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables() {
          let begin = new Date(this.dateRange.date?.[0] ?? new Date())
          let end = new Date(this.dateRange.date?.[1] ?? new Date())
          end.setHours(24)
          end = new Date(end.getTime())
          return {
            input: {
              branch: this.currentBranch.id,
              branches: (this.branches.length ? this.branches : this.currentBranch.branches)?.map((el) => el.id),
              period: {
                begin,
                end
              }
            }
          }
        },
        result({ data }) {
          if (data) {
            const chart_data = {
              labels: [],
              datasets: [
                {
                  label: 'Оборот в закуп. ценах, ₽ ',
                  backgroundColor: '#FFF8DF',
                  borderColor: '#FFC700',
                  data: []
                },
                {
                  label: 'Оборот в роз. ценах, ₽',
                  backgroundColor: '#F9E8FF',
                  borderColor: '#BD00FF',
                  data: []
                }
              ]
            }

            data?.SalesAnalyticsGraph?.sales?.forEach((item) => {
              chart_data.datasets[0].data.push({
                y: item.purchase,
                x: item.date
              })
              chart_data.datasets[1].data.push({
                y: item.retail,
                x: item.date
              })
            })
            this.purchaseSum = chart_data.datasets[0].data.reduce((sum, el) => sum + el.y, 0)
            this.retailSum = chart_data.datasets[1].data.reduce((sum, el) => sum + el.y, 0)
            this.$refs['salesAnalysis'].renderChart(chart_data, this.chartOptions)
          }
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        },
        skip() {
          return !this.dateRange.date?.[0] || !this.dateRange.date?.[1]
        }
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        dateRange: 'analytics/getDateRange',
        branches: 'analytics/getBranches'
      })
    },
    methods: {
      formatPrice(val) {
        return formatMoney(val, 2)
      }
    },
    data() {
      return {
        chart_id: 'line-chart',
        updating: false,
        chart_settings: {
          height: 210
        },
        purchaseSum: 0,
        retailSum: 0,
        dataset_id_key: 'label',
        plugins: [],
        chartData: {
          datasets: [
            {
              label: 'Оборот в закуп. ценах, ₽',
              backgroundColor: '#FFF8DF',
              borderColor: '#FFC700',
              data: [],
              fill: true
            },
            {
              label: 'Оборот в роз  . ценах, ₽',
              backgroundColor: '#F9E8FF',
              borderColor: '#BD00FF',
              data: [],
              fill: true
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          parsing: {
            yAxisKey: 'y'
          }
        }
      }
    }
  }
</script>
