<template>
  <section id="cash-sales-report">
    <!-- <h1 class="mb-3">Анализ продаж</h1> -->
    <categories-load-modal
      :included_categories="opened_category"
      @add-categories="setOpenedCategory"
    />
    <div class="filters-row">
      <div class="filter-item">
        <work-period-analytics />
      </div>
      <!--      <div class="filter-item">-->
      <!--        <i-select placeholder="Все группы товаров" />-->
      <!--      </div>-->
      <div
        class="filter-item"
        @click.capture="showLoadModal"
      >
        <div class="categories-select">
          <span style="color: black">{{ categoriesCount }}</span>
          <img
            src="/img/icons/arrow_up.svg"
            style="transform: rotate(180deg)"
          />
        </div>
      </div>
      <div
        v-if="currentBranch?.branches"
        class="filter-item"
      >
        <e-select
          :value="branches"
          class="e-select-el"
          :options="currentBranch?.branches"
          multiple
          :max_selected_count="1"
          :background_color="'#e0edff'"
          :color_text="'#00A3FF'"
          :color_close_svg="'#00A3FF'"
          :placeholder="currentBranch?.name"
          select_name="branch_сlassif"
          @input="setBranches"
        >
          <template #custom_name="{ item }">
            <div>
              <div class="e-select-el__option-name">{{ item.name }}</div>
            </div>
          </template>
        </e-select>
      </div>
      <div class="filter-item">
        <i-select
          id="storage"
          v-model="selectedStorage"
          placeholder="Все места хранения"
          multiple
          filterable
        >
          <i-option
            v-for="item in storage_list"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </i-option>
        </i-select>
      </div>
      <div class="filter-item">
        <i-select
          v-model="selectedSupplier"
          filterable
          placeholder="Все поставщики"
          multiple
        >
          <i-option
            v-for="option of suppliers"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </i-option>
        </i-select>
      </div>
      <div
        class="clear-text"
        @click="clearFilters"
      >
        Сбросить
      </div>
    </div>
    <!--    <sales-analysis-period :period="dateRange.date" />-->
    <margin-by-date
      class="mt-3"
      :period="dateRange.date"
    />

    <div class="analytics-header-row">
      <!--      <b-button class="" variant="light">Печать</b-button>-->
      <!--      <b-button class="ml-auto" variant="light">Скачать</b-button>-->
    </div>
    <b-row>
      <b-col cols="12">
        <b-card
          class="p-0"
          no-body
        >
          <div class="analytics-table-header">
            <div class="analytics-table-filter">
              <analytics-product-search
                :products="products"
                @input="setProducts"
              />
              <group-by
                type="sales"
                class="ml-3 mr-3"
              />
              <div class="d-flex ml-auto">
                <e-button
                  style="height: 32px"
                  class="mr-3"
                  @click="downloadExel"
                >
                  <img
                    style="margin-right: 12px"
                    src="/img/icons/download.svg"
                    alt="download"
                  />Скачать
                </e-button>
                <b-dropdown
                  no-caret
                  variant="none"
                >
                  <template #button-content>
                    <div class="btn-more">
                      <img
                        src="/img/icons/settings.svg"
                        alt=""
                      />
                    </div>
                  </template>
                  <b-dropdown-item v-b-modal.sort-table-modal> Изменить столбцы </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <sales-analysis-table
            :is_by_category="isByCategoryValue"
            :period="data_range"
            :pagination="marginTable.pagination"
            :analytics="marginTable.data"
            :loading_category="loading_category"
            :busy="loading"
            :fields="fields"
            table_name="trademargin"
            @categoryChange="opened_category_change"
            @change_sort="change_sort"
          />
        </b-card>
      </b-col>
    </b-row>
    <pagination
      :total="marginTable.items_total"
      table_name="sales-analytics"
      :default_take="50"
      @change_pagination="handlerPagination"
    />
  </section>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import Pagination from '@/views/shared/components/table/Pagination'
  import { mapActions, mapGetters } from 'vuex'
  import { formatMoney } from '@/utils/formatMoney'
  import SalesAnalysisPeriod from '@/views/analytics/charts/SalesAnalysisPeriod'
  import SalesAnalysisTable from '@/views/analytics/components/SalesAnalysisTable'
  import ProductSearchSelect from '@/views/analytics/components/ProductSearchSelect.vue'
  import WorkPeriodAnalytics from '@/views/analytics/components/WorkPeriodAnalytics.vue'
  import CategoriesLoadModal from '@/views/operational-processes/components/inventory/CategoriesLoadModal.vue'
  import AnalyticsItemRow from '@/views/analytics/components/MovementAnalytics/AnalyticsItemRow.vue'
  import AnalyticsProductSearch from '@/views/analytics/components/AnalyticsProductSearch.vue'
  import GroupBy from '@/views/analytics/components/GroupBy.vue'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal.vue'
  import MarginByDate from '@/views/analytics/charts/MarginByDate.vue'
  import InfoWidget from '@/views/analytics/components/TradingMargin/InfoWidget.vue'

  export default {
    components: {
      InfoWidget,
      MarginByDate,
      ProductMovementModal,
      GroupBy,
      AnalyticsProductSearch,
      AnalyticsItemRow,
      CategoriesLoadModal,
      WorkPeriodAnalytics,
      ProductSearchSelect,
      SalesAnalysisPeriod,
      SalesAnalysisTable,
      Pagination
    },
    apollo: {
      SalesAnalytics: {
        query: require('../gql/SalesAnalytics.gql'),
        fetchPolicy: 'no-cache',
        debounce: 300,
        notifyOnNetworkStatusChange: true,
        deep: true,
        variables() {
          let begin = new Date(this.dateRange.date?.[0] ?? new Date())
          let end = new Date(this.dateRange.date?.[1] ?? new Date())
          end.setHours(24)
          end = new Date(end.getTime())
          return {
            input: {
              branches: (this.branches.length ? this.branches : this.currentBranch.branches)?.map((el) => el.id),
              branch: this.currentBranch.id,
              period: {
                begin,
                end
              },
              supplier: this.selectedSupplier,
              storage: this.selectedStorage,
              byCategory: this.isByCategoryValue,
              checked: this.checked,
              products: this.products.map((el) => el.id),
              group_by: this.getGroupBy['sales'].filter((el) => el.grouped).map((el) => el.id),
              category: this.opened_category,
              byRawMaterials: this.getAnalyticsParams.byRawMaterials,
              pagination: {
                order: this.marginTable.pagination.order,
                skip: this.marginTable.pagination.skip,
                take: this.marginTable.pagination.take
              }
            }
          }
        },
        skip() {
          let begin = new Date(this.dateRange.date?.[0])
          let end = new Date(this.dateRange.date?.[1])
          if (!begin || !end) return true
          if (begin == 'Invalid Date' || end == 'Invalid Date') return true
          return !this.dateRange.date?.length
        },
        result({ data }) {
          if (!data?.SalesAnalytics) return
          this.loading = false
          this.loading_category = {}
          this.marginTable.data = data.SalesAnalytics.analytics
          this.marginTable.items_total = data.SalesAnalytics.total
        },
        error(error) {
          this.loading = false
          this.loading_category = {}
          console.error(`Ошибка запроса: ${error}`)
        }
      },
      Suppliers: {
        query: require('../../products/gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.suppliers = data.Suppliers ?? []
        }
      },
      Storages: {
        query: require('../../operational-processes/gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data?.Storages
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      ProductSearch: {
        query: require('../gql/ProductSearch.gql'),
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables() {
          return {
            input: {
              name: this.searchText,
              branch: this.currentBranch.id
            }
          }
        },
        result({ data }) {
          this.found_products = data?.ProductSearch?.products || []
        }
      }
    },
    data() {
      return {
        data_range: [],
        suppliers: [],
        selectedCategories: [],
        found_products: [],
        selectedSupplier: [],
        selectedStorage: [],
        selectedOperation: [],
        selectedCategory: [],
        selectedEntity: [],
        storage_list: [],
        isByCategory: 'byCategory',
        fields: [
          {
            key: 'product',
            width: 450,
            label: 'Товарная группа / Наименование товара',
            rowspan: 2,
            checked: true
          },
          {
            key: 'sales_remains',
            label: 'Кол-во продаж',
            checked: true,
            sortable: true,
            sort: null
          },
          {
            key: 'sales_purchase',
            label: 'Сумма в закупе, ₽',
            checked: true,
            sortable: true,
            sort: null
          },
          {
            key: 'sales_retail',
            label: 'Сумма в рознице ₽',
            checked: true,
            sortable: true,
            sort: null
          },
          {
            key: 'full_sales_retail',
            label: 'Розница б/с',
            checked: false,
            sortable: true,
            sort: null
          },
          {
            key: 'margin',
            width: 110,
            label: 'Прибыль',
            formatter: formatMoney,
            class: 'text-right',
            thStyle: 'width: 200px',
            checked: true,
            sortable: true,
            sort: null
          },
          {
            key: 'markup',
            width: 120,
            label: 'Наценка %',
            class: 'text-right',
            formatter: formatMoney,
            thStyle: 'width: 200px',
            checked: true,
            sortable: true,
            sort: null
          },
          {
            key: 'full_markup',
            width: 120,
            label: 'Наценка б/с %',
            class: 'text-right',
            formatter: formatMoney,
            thStyle: 'width: 200px',
            checked: false,
            sortable: true,
            sort: null
          },
          {
            key: 'sales_speed',
            width: 110,
            label: 'Скорость продаж',
            class: 'text-right',
            formatter: formatMoney,
            thStyle: 'width: 200px',
            checked: true,
            sortable: true,
            sort: null
          }
        ],
        options: [
          { value: 'byCategory', text: 'По группам товаров' },
          { value: 'byProduct', text: 'По товарам' }
        ],

        searchText: '',
        marginTable: {
          data: [],
          pagination: {
            skip: 0,
            take: 50,
            order: [
              {
                key: 'margin',
                value: 'DESC'
              }
            ]
          },
          items_total: 10,
          debounce: null
        },
        loading_category: {},
        loading: true
      }
    },

    mounted() {
      this.setBreadcrumbs({ is_analytics: true })
      this.prepareDates()
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        dateRange: 'analytics/getDateRange',
        opened_category: 'analytics/getOpenedCategory',
        products: 'analytics/getProduct',
        checked: 'analytics/getChecked',
        branches: 'analytics/getBranches',
        getAnalyticsParams: 'analytics/getAnalyticsParams',
        getGroupBy: 'analytics/getAbcGroupBy'
      }),
      isByCategoryValue() {
        return this.isByCategory === 'byCategory'
      },
      categoriesCount() {
        if (this.opened_category.length) {
          return 'Выбрано ' + this.opened_category.length
        }
        return 'Все группы товаров'
      }
    },

    methods: {
      ...mapActions({
        setOpenedCategory: 'analytics/setOpenedCategory',
        setProducts: 'analytics/setProducts',
        setChecked: 'analytics/setChecked',
        setBreadcrumbs: 'breadcrumbs/set_current',
        setBranches: 'analytics/setBranches'
      }),
      showLoadModal() {
        this.$bvModal.show('categories-modal')
      },
      async downloadExel() {
        let begin = new Date(this.dateRange.date?.[0] ?? new Date())
        let end = new Date(this.dateRange.date?.[1] ?? new Date())
        const { data } = await this.$apollo.query({
          query: require('../gql/SalesAnalyticsExel.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              branch: this.currentBranch.id,
              branches: (this.branches.length ? this.branches : this.currentBranch.branches)?.map((el) => el.id),
              period: {
                begin: begin,
                end: end
              },
              supplier: this.selectedSupplier,
              storage: this.selectedStorage,
              products: this.products.map((el) => el.id),
              category: this.selectedCategories,
              byRawMaterials: this.getAnalyticsParams.byRawMaterials
            }
          }
        })
        if (data?.SalesAnalyticsExel) {
          const link = document.createElement('a')
          link.href = data?.SalesAnalyticsExel?.url
          link.download = 'exel'
          link.click()
          URL.revokeObjectURL(link.href)
        } else {
          this.$noty.error('Ошибка при загруке файла')
        }
      },
      updateDateRange() {
        this.$apollo.queries.SalesAnalytics.refetch()
      },
      handlerPagination({ current_page = null, take = null }) {
        this.loading = true
        if (current_page) this.marginTable.pagination.skip = Number((current_page - 1) * take)
        if (take) this.marginTable.pagination.take = take
      },

      formatPrice(val) {
        return formatMoney(val)
      },

      convertDatetoTime(date) {
        if (date) return formatDate(date, 'date')
      },
      prepareDates() {
        const one_month_ago = new Date()
        const today = new Date()

        this.data_range = [
          this.convertDatetoTime(new Date(one_month_ago.setDate(one_month_ago.getDate() - 7))),
          this.convertDatetoTime(today)
        ]
      },
      clearFilters() {
        this.selectedSupplier = []
        this.selectedStorage = []
        const one_month_ago = new Date()
        const today = new Date()
        one_month_ago.setDate(one_month_ago.getDate() - 7)
        this.data_range = [one_month_ago, today]
        this.setOpenedCategory([])
        this.setBranches([])
      },
      changeMarginTableSearch(value) {
        clearTimeout(this.marginTable.debounce)
        this.marginTable.debounce = setTimeout(() => {
          this.searchText = value
        }, 300)
      },
      set_tags(tags) {
        this.setProducts(tags)
      },
      opened_category_change(id) {
        this.loading_category[id] = true
      },
      addCategories(cats) {
        this.selectedCategories = cats
      },
      change_sort(tr) {
        this.loading = true
        const mapping = {
          incoming_remains: 'inc_delta',
          incoming_purchase: 'inc_purchase',
          incoming_retail: 'inc_retail',
          sales_remains: 'purchase_delta',
          sales_purchase: 'purchase_purchase',
          sales_retail: 'purchase_retail',
          loss_remains: 'loses_delta',
          loss_purchase: 'loses_purchase',
          loss_retail: 'loses_retail',
          begin_delta: 'start_rem',
          begin_purchase: 'start_rem_purchase',
          end_remains: 'end_rem',
          end_purchase: 'end_rem_purchase',
          margin: 'margin',
          markup: 'markup',
          sales_speed: 'sales_speed',
          full_markup: 'full_markup'
        }
        this.marginTable.pagination.order = [
          {
            key: mapping[tr.key],
            value: tr.sort
          }
        ]
      }
    }
  }
</script>
<style lang="scss" scoped>
  .categories-select {
    display: flex;
    flex-direction: row;
    background: white;
    border: 1px solid #bcbcbc;
    border-radius: 2px;
    height: 32px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    justify-content: space-between;
  }
  .analytics-table-filter {
    display: flex;
    flex: 1;
  }
</style>

<style scoped lang="scss">
  .buttons {
    display: flex;
    align-items: stretch;
    ::v-deep() {
      button {
        align-items: center;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  ::v-deep() {
    .b-filter-date {
      width: 208px;
      margin-top: 32px;
    }

    .chart-block {
      display: flex;
      flex: 1;
      max-height: 100%;

      .card {
        flex: 1;
        max-height: 100%;
        margin-bottom: 0px;

        .card-body {
          max-height: 100%;
        }
      }
    }

    .widget-height {
      height: 275px;
      margin-bottom: 16px;
    }

    .widget-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #313131;
    }
  }

  .analytics-header-row {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
  }

  ::v-deep() {
    .analytics-header-select {
      width: 260px;
    }

    .widget-row {
      display: flex;
    }

    .widget-total {
      white-space: nowrap;
      display: flex;
    }

    .analytics-table-header {
      padding: 16px;

      input.filter-search {
        width: 350px;
        padding-left: 32px;
        background: url(/img/icons/input-search.svg) 12px center no-repeat;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .filters-row {
    height: fit-content !important;
    align-items: center;
    display: flex;

    .clear-text {
      cursor: pointer;
    }

    .filter-item {
      margin-right: 16px;
      width: 20%;
    }
  }
</style>
