var render = function render(){
  var _vm$analytics$find;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('product-movement-modal', {
    attrs: {
      "product": (_vm$analytics$find = _vm.analytics.find(function (obj) {
        var _obj$product;

        return ((_obj$product = obj.product) === null || _obj$product === void 0 ? void 0 : _obj$product.id) === _vm.product_selected[0];
      })) === null || _vm$analytics$find === void 0 ? void 0 : _vm$analytics$find.product
    }
  }), _c('b-overlay', {
    attrs: {
      "spinner-variant": "primary",
      "show": _vm.busy,
      "no-center": ""
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "top": "100px",
            "right": "50%",
            "position": "absolute"
          }
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('custom-navbar', {
    staticStyle: {
      "position": "absolute",
      "bottom": "7px",
      "left": "10px",
      "right": "10px"
    },
    attrs: {
      "items": _vm.product_selected
    },
    on: {
      "clear": function ($event) {
        _vm.product_selected = [];
      }
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_vm.product_selected.length === 1 ? _c('b-button', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.showMovement
          }
        }, [_vm._v(" Показать товародвижение ")]) : _vm._e(), _c('b-dropdown', {
          staticClass: "navbar-dropdown",
          attrs: {
            "dropup": "",
            "no-caret": "",
            "variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticStyle: {
                  "font-size": "16px"
                }
              }, [_vm._v("Создать документ")])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.revaluate('incoming');
            }
          }
        }, [_vm._v(" Документ прихода ")]), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.revaluate('write-off');
            }
          }
        }, [_vm._v(" Документ списания ")]), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.revaluate('return');
            }
          }
        }, [_vm._v(" Документ возврата ")]), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.revaluate('movement');
            }
          }
        }, [_vm._v(" Документ перемещения ")]), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.revaluate('inventory');
            }
          }
        }, [_vm._v(" Документ инвентаризации ")]), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.revaluate('sale');
            }
          }
        }, [_vm._v(" Документ продажи ")]), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.revaluate('revaluation');
            }
          }
        }, [_vm._v(" Документ переоценки ")]), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.revaluate('promo');
            }
          }
        }, [_vm._v(" Маркетинговую акцию ")])], 1)];
      },
      proxy: true
    }])
  }), _c('resizable-table', {
    attrs: {
      "table_name": _vm.table_name,
      "items": _vm.analytics,
      "default_fields": _vm.fields,
      "inside_card": false,
      "no_drag": ""
    },
    on: {
      "sort_change": _vm.sort
    },
    scopedSlots: _vm._u([{
      key: "body_product",
      fn: function (_ref) {
        var _item$product;

        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex justify-content-start"
        }, [_c('analytics-item-row', {
          attrs: {
            "loading_category": _vm.loading_category,
            "row": item,
            "items": _vm.group,
            "selected": _vm.isProductSelected((_item$product = item.product) === null || _item$product === void 0 ? void 0 : _item$product.id)
          },
          on: {
            "select_product": _vm.selectProduct,
            "open_category": _vm.open_category
          }
        })], 1)];
      }
    }, {
      key: "body_begin_delta",
      fn: function (_ref2) {
        var _item$begin;

        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDelta((_item$begin = item.begin) === null || _item$begin === void 0 ? void 0 : _item$begin.remains)) + " ")])];
      }
    }, {
      key: "body_begin_purchase",
      fn: function (_ref3) {
        var _item$begin2;

        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice((_item$begin2 = item.begin) === null || _item$begin2 === void 0 ? void 0 : _item$begin2.purchase)) + " ")])];
      }
    }, {
      key: "body_incoming_remains",
      fn: function (_ref4) {
        var _item$income;

        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDelta((_item$income = item.income) === null || _item$income === void 0 ? void 0 : _item$income.remains)) + " ")])];
      }
    }, {
      key: "body_incoming_purchase",
      fn: function (_ref5) {
        var _item$income2;

        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice((_item$income2 = item.income) === null || _item$income2 === void 0 ? void 0 : _item$income2.purchase)) + " ")])];
      }
    }, {
      key: "body_incoming_retail",
      fn: function (_ref6) {
        var _item$income3;

        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice((_item$income3 = item.income) === null || _item$income3 === void 0 ? void 0 : _item$income3.retail)) + " ")])];
      }
    }, {
      key: "body_sales_remains",
      fn: function (_ref7) {
        var _item$sales;

        var item = _ref7.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDelta((_item$sales = item.sales) === null || _item$sales === void 0 ? void 0 : _item$sales.remains)) + " ")])];
      }
    }, {
      key: "body_sales_purchase",
      fn: function (_ref8) {
        var _item$sales2;

        var item = _ref8.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice((_item$sales2 = item.sales) === null || _item$sales2 === void 0 ? void 0 : _item$sales2.purchase)) + " ")])];
      }
    }, {
      key: "body_sales_retail",
      fn: function (_ref9) {
        var _item$sales3;

        var item = _ref9.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice((_item$sales3 = item.sales) === null || _item$sales3 === void 0 ? void 0 : _item$sales3.retail)) + " ")])];
      }
    }, {
      key: "body_full_sales_retail",
      fn: function (_ref10) {
        var _item$sales4;

        var item = _ref10.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice((_item$sales4 = item.sales) === null || _item$sales4 === void 0 ? void 0 : _item$sales4.full_retail)) + " ")])];
      }
    }, {
      key: "body_loss_remains",
      fn: function (_ref11) {
        var _item$loses;

        var item = _ref11.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDelta((_item$loses = item.loses) === null || _item$loses === void 0 ? void 0 : _item$loses.remains)) + " ")])];
      }
    }, {
      key: "body_loss_purchase",
      fn: function (_ref12) {
        var _item$loses2;

        var item = _ref12.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice((_item$loses2 = item.loses) === null || _item$loses2 === void 0 ? void 0 : _item$loses2.purchase)) + " ")])];
      }
    }, {
      key: "body_loss_retail",
      fn: function (_ref13) {
        var _item$loses3;

        var item = _ref13.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice((_item$loses3 = item.loses) === null || _item$loses3 === void 0 ? void 0 : _item$loses3.retail)) + " ")])];
      }
    }, {
      key: "body_end_remains",
      fn: function (_ref14) {
        var _item$end;

        var item = _ref14.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDelta((_item$end = item.end) === null || _item$end === void 0 ? void 0 : _item$end.remains)) + " ")])];
      }
    }, {
      key: "body_end_purchase",
      fn: function (_ref15) {
        var _item$end2;

        var item = _ref15.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice((_item$end2 = item.end) === null || _item$end2 === void 0 ? void 0 : _item$end2.purchase)) + " ")])];
      }
    }, {
      key: "body_margin",
      fn: function (_ref16) {
        var item = _ref16.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.margin)) + " ")])];
      }
    }, {
      key: "body_markup",
      fn: function (_ref17) {
        var item = _ref17.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.markup)) + " ")])];
      }
    }, {
      key: "body_full_markup",
      fn: function (_ref18) {
        var item = _ref18.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.full_markup)) + " ")])];
      }
    }, {
      key: "body_sales_speed",
      fn: function (_ref19) {
        var item = _ref19.item;
        return [_c('div', {
          staticClass: "text-right table-analysis-column",
          class: {
            'total-text': _vm.isTotal(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.sales_speed)) + " ")])];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }