<template>
  <div style="position: relative">
    <product-movement-modal :product="analytics.find((obj) => obj.product?.id === product_selected[0])?.product" />
    <b-overlay
      spinner-variant="primary"
      :show="busy"
      no-center
    >
      <template #overlay>
        <div style="top: 100px; right: 50%; position: absolute">
          <b-spinner variant="primary" />
        </div>
      </template>
      <custom-navbar
        style="position: absolute; bottom: 7px; left: 10px; right: 10px"
        :items="product_selected"
        @clear="product_selected = []"
      >
        <template #buttons>
          <b-button
            v-if="product_selected.length === 1"
            variant="dark"
            @click="showMovement"
          >
            Показать товародвижение
          </b-button>
          <b-dropdown
            dropup
            no-caret
            class="navbar-dropdown"
            variant="dark"
          >
            <template #button-content> <div style="font-size: 16px">Создать документ</div> </template>
            <b-dropdown-item @click="revaluate('incoming')"> Документ прихода </b-dropdown-item>
            <b-dropdown-item @click="revaluate('write-off')"> Документ списания </b-dropdown-item>
            <b-dropdown-item @click="revaluate('return')"> Документ возврата </b-dropdown-item>
            <b-dropdown-item @click="revaluate('movement')"> Документ перемещения </b-dropdown-item>
            <b-dropdown-item @click="revaluate('inventory')"> Документ инвентаризации </b-dropdown-item>
            <b-dropdown-item @click="revaluate('sale')"> Документ продажи </b-dropdown-item>
            <b-dropdown-item @click="revaluate('revaluation')"> Документ переоценки </b-dropdown-item>
            <b-dropdown-item @click="revaluate('promo')"> Маркетинговую акцию </b-dropdown-item>
          </b-dropdown>
        </template>
      </custom-navbar>
      <resizable-table
        :table_name="table_name"
        :items="analytics"
        :default_fields="fields"
        :inside_card="false"
        no_drag
        @sort_change="sort"
      >
        <template #body_product="{ item }">
          <div class="d-flex justify-content-start">
            <analytics-item-row
              :loading_category="loading_category"
              :row="item"
              :items="group"
              :selected="isProductSelected(item.product?.id)"
              @select_product="selectProduct"
              @open_category="open_category"
            />
          </div>
        </template>
        <template #body_begin_delta="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatDelta(item.begin?.remains) }}
          </div>
        </template>
        <template #body_begin_purchase="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.begin?.purchase) }}
          </div>
        </template>
        <template #body_incoming_remains="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatDelta(item.income?.remains) }}
          </div>
        </template>
        <template #body_incoming_purchase="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.income?.purchase) }}
          </div>
        </template>
        <template #body_incoming_retail="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.income?.retail) }}
          </div>
        </template>
        <template #body_sales_remains="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatDelta(item.sales?.remains) }}
          </div>
        </template>
        <template #body_sales_purchase="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.sales?.purchase) }}
          </div>
        </template>
        <template #body_sales_retail="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.sales?.retail) }}
          </div>
        </template>
        <template #body_full_sales_retail="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.sales?.full_retail) }}
          </div>
        </template>
        <template #body_loss_remains="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatDelta(item.loses?.remains) }}
          </div>
        </template>
        <template #body_loss_purchase="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.loses?.purchase) }}
          </div>
        </template>
        <template #body_loss_retail="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.loses?.retail) }}
          </div>
        </template>
        <template #body_end_remains="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatDelta(item.end?.remains) }}
          </div>
        </template>
        <template #body_end_purchase="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.end?.purchase) }}
          </div>
        </template>
        <template #body_margin="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.margin) }}
          </div>
        </template>
        <template #body_markup="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.markup) }}
          </div>
        </template>
        <template #body_full_markup="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.full_markup) }}
          </div>
        </template>
        <template #body_sales_speed="{ item }">
          <div
            class="text-right table-analysis-column"
            :class="{ 'total-text': isTotal(item) }"
          >
            {{ formatPrice(item.sales_speed) }}
          </div>
        </template>
      </resizable-table>
    </b-overlay>
  </div>
</template>

<script>
  import { formatMoney } from '@/utils/formatMoney'
  import AnalyticsItemRow from '@/views/analytics/components/MovementAnalytics/AnalyticsItemRow.vue'
  import SortColumn from '@/views/analytics/components/SortColumn.vue'
  import ResizableTable from '@/components/ResizableTable'
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar.vue'
  import { create_document_by_type } from '@/utils/create_document_by_type'
  import { mapGetters } from 'vuex'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal.vue'

  export default {
    name: 'SalesAnalysisTable',
    components: { ProductMovementModal, CustomNavbar, SortColumn, AnalyticsItemRow, ResizableTable },
    props: {
      fields: Array,
      is_by_category: Boolean,
      pagination: Object,
      analytics: {
        type: Array,
        default: () => {
          return []
        }
      },
      period: {
        type: Array,
        default: () => {
          return []
        }
      },
      opened_category: Array,
      table_name: String,
      loading_category: Object,
      busy: Boolean
    },
    data: () => ({
      product_selected: []
    }),
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      group() {
        return [...this.$store.state.analytics.group_by.sales.filter((el) => el.grouped).map((el) => el.id), 'product']
      }
    },
    methods: {
      showMovement() {
        this.$bvModal.show('product-movement')
      },
      async revaluate(type) {
        const doc = create_document_by_type(
          type,
          this.currentBranch.id,
          this.product_selected.map((el) => {
            const product = this.analytics.find((item) => item.product?.id === el)?.product
            return {
              product: product.id,
              price: product.purchasePrice || 0,
              retailPrice: product.retailPrice || 0,
              delta: 0
            }
          }),
          this.storage_list?.[0]?.id
        )
        const { data } = await this.$apollo.mutate({
          mutation: doc.mutation,
          variables: doc.variables
        })
        const route = doc.getRoute(data)
        if (route) this.$router.push(route)
      },
      isProductSelected(id) {
        if (id) return this.product_selected.includes(id)
        return false
      },
      selectProduct(val) {
        if (!val) return
        if (!this.product_selected.includes(val)) {
          this.product_selected.push(val)
        } else {
          this.product_selected = this.product_selected.filter((obj) => obj !== val)
        }
      },
      formatPrice(val) {
        return formatMoney(val, 2)
      },
      formatDelta(val) {
        return new Intl.NumberFormat('ru-RU', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 3
        }).format(val)
      },
      open_category(path) {
        this.$emit('categoryChange', path)
      },
      isOpened(id) {
        return this.opened_category.some((el) => el === id)
      },
      setCategoryPath(category) {
        let path = category?.parent_groups?.name || ''
        let parent = category?.parent_groups
        while (parent?.parent_groups?.name) {
          path = path + ' / ' + parent.parent_groups.name
          parent = parent.parent_groups
        }
        return path
      },
      sort(tr) {
        this.$emit('change_sort', tr)
      },
      isTotal(item) {
        return !(item.category || item.product || item.supplier || item.storage || item.branch)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .product-info {
      margin-top: 2px;
      font-style: normal;
      color: #888888;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    }
  }
  .total-text {
    font-weight: 600;
  }
</style>
