<template>
  <div>
    <div class="widget-height widget-row mt-3">
      <div class="widget-total">
        <b-card class="p-0 mb-0">
          <div class="widget-title">Всего за период</div>
          <div class="d-flex">
            <div class="mr-3 mt-3">
              <info-widget
                title="Прибыль, ₽"
                :value="formatPrice(totalInfo.profit)"
                :type="'margin'"
                :current_type="type"
                @click="(val) => (type = val)"
              />
              <info-widget
                title="Ср. наценка (факт), %"
                class="mt-3"
                :type="'markup'"
                :current_type="type"
                :value="totalInfo.markup?.toFixed(2) || 0 + ' %'"
                @click="(val) => (type = val)"
              />
            </div>
            <div class="mt-3">
              <info-widget
                title="Выручка, ₽"
                :value="formatPrice(totalInfo.retail)"
                :type="'retail'"
                :current_type="type"
                @click="(val) => (type = val)"
              />

              <info-widget
                class="mt-3"
                title="Рентабельность, %"
                :value="totalInfo.profitability?.toFixed(2) || 0 + ' %'"
                :type="'profit'"
                :current_type="type"
                @click="(val) => (type = val)"
              />
            </div>
          </div>
        </b-card>
      </div>
      <div class="widget-graph chart-block pl-0 ml-3">
        <b-card class="p-0">
          <div class="widget-title">Детализация за период</div>
          <bar
            ref="marginByDate"
            :chart-options="chartOptions"
            :chart-data="chart_data"
            chart-id="column"
            :height="chart_settings.height"
            css-classes="chart_sum"
          />
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Bar } from 'vue-chartjs/legacy'
  import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
  import { externalTooltipHandler } from '@/views/analytics/charts/external/marginByDate'
  import InfoWidget from '@/views/analytics/components/TradingMargin/InfoWidget.vue'
  import { formatMoney } from '@/utils/formatMoney'
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

  export default {
    name: 'MarginByDate',
    components: { InfoWidget, Bar },
    props: {
      period: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {
        type: 'margin',
        totalInfo: {
          markup: 0,
          profit: 0,
          profitability: 0,
          retail: 0
        },
        loading: false,
        analytics: [],
        chart_settings: {
          height: 210
        },
        chart_data: {
          labels: [],
          datasets: [
            {
              backgroundColor: '#c5dbba',
              data: []
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          parsing: {
            yAxisKey: 'y'
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          },
          plugins: {
            legend: {
              display: true
            },
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,
              intersect: false,
              mode: 'index',
              position: 'average',
              displayColors: false,
              external: externalTooltipHandler
            }
          }
        }
      }
    },
    watch: {
      type() {
        this.render()
      }
    },
    apollo: {
      SalesAnalyticsGraph: {
        query: require('../gql/SalesAnalyticsGraph.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          let begin = new Date(this.period[0])
          let end = new Date(this.period[1])
          end.setHours(24)
          let userTimezoneOffset = end.getTimezoneOffset() * 60000
          end = new Date(end.getTime() - userTimezoneOffset)
          return {
            input: {
              branches:
                this.currentBranch?.__typename === 'BranchClassifModel'
                  ? (this.branches.length ? this.branches : this.currentBranch.branches)?.map((el) => el.id)
                  : [this.currentBranch.id],
              branch: this.currentBranch.id,
              period: {
                begin,
                end
              }
            },
            group: this.currentBranch?.__typename === 'BranchClassifModel' ? this.currentBranch.id : null
          }
        },
        result({ data }) {
          if (!data?.SalesAnalyticsGraph) return
          this.analytics = data?.SalesAnalyticsGraph?.sales
          this.totalInfo = {
            markup: data?.SalesAnalyticsGraph?.markup * 100,
            profit: data?.SalesAnalyticsGraph?.profit,
            profitability: data?.SalesAnalyticsGraph?.profitability * 100,
            retail: data?.SalesAnalyticsGraph?.retail
          }
          this.render()
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      }
    },
    methods: {
      formatPrice(val) {
        return formatMoney(val, 2)
      },
      render() {
        const type_labels = {
          retail: 'Себестоимость',
          margin: 'Прибыль',
          markup: 'Наценка',
          profit: 'Рентабельность'
        }
        const chart_data = {
          labels: [],
          datasets: [
            {
              backgroundColor: [],
              label: type_labels[this.type],
              data: [],
              borderRadius: 4
            }
          ]
        }
        if (this.type === 'retail') {
          chart_data.datasets.push({
            backgroundColor: [],
            label: 'Прибыль',
            data: []
          })
          chart_data.datasets.push({
            backgroundColor: [],
            label: 'Возвраты',
            data: []
          })
        }

        this.analytics?.forEach((item) => {
          let value = 0
          if (this.type === 'margin') {
            value = item.retail - item.purchase
          }
          if (this.type === 'markup') {
            value = (item.retail / item.purchase - 1) * 100
          }
          if (this.type === 'retail') {
            value = item.purchase - item.return
            chart_data.datasets[1].data.push({
              y: item.retail - item.purchase,
              x: item.date
            })
            chart_data.datasets[2].data.push({
              y: item.return,
              x: item.date
            })

            chart_data.datasets[1].backgroundColor.push('rgba(133, 232, 161, 0.8)')
            chart_data.datasets[2].backgroundColor.push('rgba(255,153,0,0.8)')
          }

          if (this.type === 'profit') {
            value = item.profitability * 100
          }
          chart_data.datasets[0].data.push({
            y: value,
            x: item.date,
            markup: (item.retail / item.purchase - 1) * 100,
            profit: item.retail - item.purchase,
            profitability: item?.profitability,
            retail: item?.retail,
            return: item?.return
          })

          chart_data.datasets[0].backgroundColor.push(
            this.type === 'retail' ? 'rgba(128,179,255,0.8)' : 'rgba(133, 232, 161, 0.8)'
          )
        })
        // this.chart_data = JSON.parse(JSON.stringify(chart_data))
        this.$refs['marginByDate'].renderChart(chart_data, this.chartOptions)
        console.log(chart_data)
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    }
  }
</script>

<style scoped></style>
