<template>
  <div>
    <img
      src=""
      alt=""
    />
  </div>
</template>
<script>
  export default {
    name: 'SortColumn'
  }
</script>
<style scoped lang="scss"></style>
