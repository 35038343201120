<template>
  <div
    v-click-outside="() => (sheet = false)"
    class="box"
    @click="sheet = !sheet"
  >
    <div>Группировка</div>
    <div
      v-if="counter"
      class="counter"
    >
      {{ counter }}
    </div>
    <arrow-svg
      class="arrow-svg"
      :class="{ rotate: sheet }"
    />
    <transition name="fade">
      <div
        v-if="sheet"
        class="sheet"
        @click.stop
      >
        <div
          class="item-none"
          @click="reset"
        >
          Без группировки
        </div>
        <draggable
          v-model="items"
          group="items"
          @start="drag = true"
          @end="drag = false"
        >
          <div
            v-for="item of items"
            :key="item.id"
            class="item"
          >
            <e-checkbox
              :checked="item.grouped"
              :value="item.id"
              @click="selectGroup(item)"
            />
            <div class="text">
              {{ item.name }}
            </div>
            <img
              data-v-c31cc8c2=""
              src="/img/icons/move_button.svg"
              alt=""
              class="ml-auto move_button"
            />
          </div>
        </draggable>
      </div>
    </transition>
  </div>
</template>
<script>
  import ArrowSvg from '@/views/analytics/components/ArrowSvg.vue'
  import draggable from 'vuedraggable'
  import { mapActions } from 'vuex'

  export default {
    components: {
      ArrowSvg,
      draggable
    },
    props: {
      type: {
        type: String,
        default: () => 'abc'
      }
    },

    data() {
      return {
        sheet: false,
        drag: false
      }
    },
    computed: {
      counter() {
        if (!Array.isArray(this.items)) return 0
        return this.items?.filter((el) => el.grouped).length
      },
      getGroupBy() {
        return this.$store.state.analytics.group_by[this.type]
      },
      items: {
        get() {
          return this.$store.state.analytics.group_by[this.type]
        },
        set(value) {
          this.setAbcGroup({ [this.type]: value })
        }
      }
    },
    mounted() {
      if (this.type === 'abc')
        if (this.getGroupBy?.length !== 3)
          this.setAbcGroup({
            [this.type]: [
              { id: 'branch', name: 'Магазин', grouped: false },
              { id: 'category', name: 'Товарная группа', grouped: false },
              { id: 'supplier', name: 'Поставщик', grouped: false }
            ]
          })
      if (this.type === 'sales')
        if (this.getGroupBy?.length !== 4)
          this.setAbcGroup({
            [this.type]: [
              { id: 'branch', name: 'Магазин', grouped: false },
              { id: 'category', name: 'Товарная группа', grouped: false },
              { id: 'supplier', name: 'Поставщик', grouped: false },
              { id: 'storage', name: 'Место хранения', grouped: false }
            ]
          })
    },
    methods: {
      ...mapActions({
        setAbcGroup: 'analytics/setGroupBy'
      }),
      selectGroup(item) {
        item.grouped = !item.grouped
        this.setAbcGroup({ [this.type]: this.getGroupBy })
      },
      reset() {
        this.items?.forEach((obj) => (obj.grouped = false))
        this.setAbcGroup({ [this.type]: this.getGroupBy })
      }
    }
  }
</script>
<style scoped lang="scss">
  .arrow-svg {
    transition: all 0.3s ease-in-out;
    &.rotate {
      transform: rotate(180deg);
    }
  }
  .box {
    display: flex;
    padding: 4px 9px 4px 9px;
    align-items: center;
    gap: 10px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid var(--Text-placeholder---gray300, #bcbcbc);
    background: var(--Gray-White, #fff);
    color: var(--Text-primary---gray700, #313131);
    cursor: pointer;
    position: relative;
    .counter {
      border-radius: 4px;
      color: #ffffff;
      padding: 1px 6px 1px 6px;
      background: var(--Text-system---blue400, #00a3ff);
    }
  }
  .sheet {
    align-items: stretch;
    width: 200px;
    top: 44px;
    left: 0px;
    position: absolute;
    display: inline-flex;
    border-radius: 4px;
    border: 1px solid var(--Gray-gray100, #e2e2e2);
    background: #fff;
    padding: 6px 0px;
    flex-direction: column;
    /* Выпадашки */
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.06);
    z-index: 10;
    .item-none {
      white-space: nowrap;
      padding: 6px 0px 12px 12px;
      border-bottom: 1px solid var(--Gray-gray100, #e2e2e2);
      margin-bottom: 4px;
      &:hover {
        background: #f8f8f9;
      }
    }
    .item {
      white-space: nowrap;
      padding: 5px 12px 5px 12px;
      display: flex;
      align-items: center;
      .text {
        margin-left: 10px;
      }
      &:hover {
        background: #f8f8f9;
      }
    }
  }
</style>
